// TIMESTAMP
@import "core";

@include respond-to(base-inline) {
  :where(.tc_timestamp){display:block;font-size:var(--font-12-14);line-height:var(--line-140);white-space:nowrap;color:var(--color-600);}
}

@include respond-to(print) {
  .tc_timestamp{display:inline-block;font-size:var(--font-14);line-height:var(--line-140);}
  .tc_timestamp--updated{padding-left:var(--spacing-8);}
}