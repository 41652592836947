// PAGE META
@import "core";

@include respond-to(base-inline) {
  .tc_page__meta{display:flex;flex-direction:column;gap:var(--spacing-4);}
}

@include respond-to(print) {
  .tc_page__meta{margin-bottom:var(--spacing-20);}
}
