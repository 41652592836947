// EMBED TOOLS QUIZ
@import "core";

@include respond-to(base) {
  .tc_tools__quiz__questions{counter-reset:item;list-style:none;
    .tc_caption{border:0;padding-left:var(--spacing-8);padding-right:var(--spacing-8);}
  }
  .tc_tools__quiz__questions__item{border-top:var(--spacing-20) solid var(--color-100);margin-bottom:var(--spacing-112);background-color:var(--color-light);counter-increment:item;
    .tc_heading{margin:var(--spacing-8) var(--spacing-8) var(--spacing-16);
      &::before{content:counter(item);display:block;color:var(--color-800);font-size:var(--font-28-32-36);font-weight:var(--font-bold);padding-bottom:var(--spacing-4);}
    }
    &:nth-of-type(-n+9) .tc_heading::before{content:'0'counter(item);}
  }
  .tc_tools__quiz__questions__detailed-answer{background-color:transparent;margin:calc(var(--spacing-96) * -1) var(--spacing-8) var(--spacing-80);}
  .tc_tools__quiz__questions__item__answers{min-height:var(--spacing-1);overflow:hidden;
    &[data-quiz-answered="true"]{pointer-events:none;}
  }
  .tc_tools__quiz__questions__item__answers__item{list-style:none;background-color:var(--color-light);border-top:var(--spacing-2) solid var(--color-100);
    &:last-child{border-bottom:var(--spacing-2) solid var(--color-100);}
    &:focus-within{color:var(--color-light);background-color:var(--color-800);
      .tc_form__label::before{border-color:transparent;}
    }
    .tc_form__group{cursor:pointer;}
    .tc_form__radio:checked + .tc_form__label::after{opacity:1;background-color:var(--color-800);}
    .tc_form__label{border-width:var(--spacing-16) var(--spacing-8);border-style:solid;border-color:transparent;cursor:pointer;
      &::after{transition:none;}
    }

    &[data-quiz-state="picked"] .tc_form__label{color:var(--color-light);background-color:var(--color-800);}
    &[data-quiz-state="correct"] .tc_form__radio + .tc_form__label{background-color:var(--color-success);color:var(--color-white);
      &::before{border-color:var(--color-white);background:none;}
      &::after{mask:url("../../../../../../icons/check.svg") no-repeat 50%;mask-size:var(--spacing-12) auto;background-color:var(--color-white);opacity:1;}
    }
    &[data-quiz-state="wrong"] .tc_form__radio + .tc_form__label{background-color:var(--color-error);color:var(--color-white);
      &::before{border-color:var(--color-white);background:none;}
      &::after{mask:url("../../../../../../icons/x.svg") no-repeat 50%;mask-size:var(--spacing-12) auto;background-color:var(--color-white);opacity:1;}
    }
    &[data-quiz-answerimage="false"]{clear:both;}
    &[data-quiz-answerimage="true"]{float:left;overflow:hidden;width:calc(50% - var(--spacing-2));
      &:nth-child(odd){margin-right:var(--spacing-2);}
      &:nth-child(even){margin-left:var(--spacing-2);}
    }
  }
  .tc_tools__quiz__questions__item__correct-answers{text-align:center;font-size:var(--font-12);font-weight:var(--font-medium);color:var(--color-600);padding:var(--spacing-8) 0;background-color:var(--color-100);}

  .tc_tools__quiz__outcome{background-color:var(--color-light);
    .tc_caption{margin:0 var(--spacing-8);}
    .tc_heading{padding:var(--spacing-16) var(--spacing-8) var(--spacing-8);}
    .tc_richcontent{padding:0 var(--spacing-8);}
  }

  [data-object-layout]{
    .tc_tools__quiz__questions__item{
      .tc_heading::before{color:var(--color-primary-tv2red);}
    }
    .tc_tools__quiz__questions__item__answers__item{
      &:focus-within,&:not([data-quiz-state]) .tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-tv2red);}
    }
  }
  [data-object-layout="sport"]{
    .tc_tools__quiz__questions__item{
      .tc_heading::before{color:var(--color-primary-blue);}
    }
    .tc_tools__quiz__questions__item__answers__item{
      &:focus-within,&:not([data-quiz-state]) .tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-blue);}
    }
  }
  [data-object-layout="vejr"]{
    .tc_tools__quiz__questions__item{
      .tc_heading::before{color:var(--color-primary-green);}
    }
    .tc_tools__quiz__questions__item__answers__item{
      &:focus-within,&:not([data-quiz-state]) .tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-green);}
    }
  }
  [data-object-layout="echo"]{
    .tc_tools__quiz__questions__item{
      .tc_heading::before{color:var(--color-primary-violet);}
    }
    .tc_tools__quiz__questions__item__answers__item{
      &:focus-within,&:not([data-quiz-state]) .tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-violet);}
    }
  }
}

@include respond-to(hover) {
  .tc_tools__quiz__questions__item__answers__item:hover{color:var(--color-light);background-color:var(--color-800);
    .tc_form__label::before{border-color:transparent;}
  }
  [data-object-layout] .tc_tools__quiz__questions__item__answers__item:hover{background-color:var(--color-primary-tv2red);}
  [data-object-layout="sport"] .tc_tools__quiz__questions__item__answers__item:hover{background-color:var(--color-primary-blue);}
  [data-object-layout="vejr"] .tc_tools__quiz__questions__item__answers__item:hover{background-color:var(--color-primary-green);}
  [data-object-layout="echo"] .tc_tools__quiz__questions__item__answers__item:hover{background-color:var(--color-primary-violet);}
}

@include respond-to(print) {
  .tc_tools__quiz__questions__item__answers{list-style:none;padding:0;margin:0 0 var(--spacing-20);}
}
